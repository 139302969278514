export const Shiyu7_2 = [
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 8.5,
    rank: 1,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 7.37,
    rank: 2,
    avg_round: 90,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 4.77,
    rank: 3,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 4.33,
    rank: 4,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 3.78,
    rank: 5,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 3.49,
    rank: 6,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 3.47,
    rank: 7,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 3.31,
    rank: 8,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 3.23,
    rank: 9,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 3.04,
    rank: 10,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 2.88,
    rank: 11,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 2.73,
    rank: 12,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 2.65,
    rank: 13,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'caesar',
    app_rate: 2.16,
    rank: 14,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 2.16,
    rank: 14,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.48,
    rank: 16,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 1.31,
    rank: 17,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 1.25,
    rank: 18,
    avg_round: 169,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 1.03,
    rank: 19,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.94,
    rank: 20,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.92,
    rank: 21,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.92,
    rank: 21,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.9,
    rank: 23,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.8,
    rank: 24,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.8,
    rank: 24,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.76,
    rank: 26,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'rina',
    app_rate: 0.76,
    rank: 26,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.72,
    rank: 28,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.72,
    rank: 28,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.7,
    rank: 30,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.7,
    rank: 30,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'nicole-demara',
    app_rate: 0.66,
    rank: 32,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.66,
    rank: 32,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.58,
    rank: 34,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.55,
    rank: 35,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.55,
    rank: 35,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.53,
    rank: 37,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.51,
    rank: 38,
    avg_round: 161,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.47,
    rank: 39,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.45,
    rank: 40,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.45,
    rank: 40,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.45,
    rank: 40,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.43,
    rank: 43,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.41,
    rank: 44,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.41,
    rank: 44,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.39,
    rank: 46,
    avg_round: 195,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.39,
    rank: 46,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.39,
    rank: 46,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.37,
    rank: 49,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.35,
    rank: 50,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.31,
    rank: 51,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.29,
    rank: 52,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.27,
    rank: 53,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.27,
    rank: 53,
    avg_round: 207,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.27,
    rank: 53,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.27,
    rank: 53,
    avg_round: 155,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.27,
    rank: 53,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.27,
    rank: 53,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.27,
    rank: 53,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.27,
    rank: 53,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.25,
    rank: 61,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.25,
    rank: 61,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.23,
    rank: 63,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 63,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'miyabi',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 63,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 63,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.23,
    rank: 63,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 68,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 68,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 68,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.21,
    rank: 68,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 68,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 68,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 74,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 74,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 74,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.18,
    rank: 74,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.18,
    rank: 74,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 74,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 74,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 81,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 81,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 81,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 81,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.16,
    rank: 81,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 86,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 86,
    avg_round: 167,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 86,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 86,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 86,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 86,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.14,
    rank: 86,
    avg_round: 205,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 93,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 93,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 93,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 93,
    avg_round: 177,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 93,
    avg_round: 183,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 93,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 93,
    avg_round: 214,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 100,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 100,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 100,
    avg_round: 163,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 100,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 100,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.1,
    rank: 100,
    avg_round: 174,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'qingyi',
    app_rate: 0.1,
    rank: 100,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 107,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 107,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 107,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 107,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'pulchra',
    app_rate: 0.08,
    rank: 107,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 107,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 107,
    avg_round: 202,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 107,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'miyabi',
    char_three: 'yanagi',
    app_rate: 0.08,
    rank: 107,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.08,
    rank: 107,
    avg_round: 250,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 107,
    avg_round: 199,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 107,
    avg_round: 175,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 107,
    avg_round: 184,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 107,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 107,
    avg_round: 198,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 122,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 122,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 122,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 122,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 122,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 122,
    avg_round: 190,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 122,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 122,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 122,
    avg_round: 159,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 122,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.06,
    rank: 122,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 122,
    avg_round: 195,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 122,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 122,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 122,
    avg_round: 190,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 122,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'grace-howard',
    app_rate: 0.06,
    rank: 122,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 122,
    avg_round: 220,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'grace-howard',
    app_rate: 0.06,
    rank: 122,
    avg_round: 194,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'miyabi',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 122,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'miyabi',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 122,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 122,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lycaon',
    app_rate: 0.06,
    rank: 122,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 122,
    avg_round: 162,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 122,
    avg_round: 202,
    star_num: '4'
  }
];
