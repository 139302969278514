export const Shiyu7_1 = [
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 9.76,
    rank: 1,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 9.2,
    rank: 2,
    avg_round: 91,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 5.26,
    rank: 3,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 5.2,
    rank: 4,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 4.54,
    rank: 5,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 4.03,
    rank: 6,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 3.66,
    rank: 7,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 3.39,
    rank: 8,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 2.71,
    rank: 9,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 2.03,
    rank: 10,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 1.99,
    rank: 11,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.89,
    rank: 12,
    avg_round: 73,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 1.89,
    rank: 12,
    avg_round: 92,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.83,
    rank: 14,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 1.52,
    rank: 15,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.48,
    rank: 16,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.34,
    rank: 17,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.31,
    rank: 18,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.31,
    rank: 18,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 1.25,
    rank: 20,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.23,
    rank: 21,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 1.19,
    rank: 22,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.13,
    rank: 23,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 1.09,
    rank: 24,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.97,
    rank: 25,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.94,
    rank: 26,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.84,
    rank: 27,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.8,
    rank: 28,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.74,
    rank: 29,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.68,
    rank: 30,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.68,
    rank: 30,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.62,
    rank: 32,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.58,
    rank: 33,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.55,
    rank: 34,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.51,
    rank: 35,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.49,
    rank: 36,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.49,
    rank: 36,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.47,
    rank: 38,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.45,
    rank: 39,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.45,
    rank: 39,
    avg_round: 143,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 41,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.41,
    rank: 42,
    avg_round: 177,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.41,
    rank: 42,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.41,
    rank: 42,
    avg_round: 166,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.39,
    rank: 45,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.37,
    rank: 46,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.37,
    rank: 46,
    avg_round: 203,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.35,
    rank: 48,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.33,
    rank: 49,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.33,
    rank: 49,
    avg_round: 228,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.33,
    rank: 49,
    avg_round: 225,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.33,
    rank: 49,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.33,
    rank: 49,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.31,
    rank: 54,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.31,
    rank: 54,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.31,
    rank: 54,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'pulchra',
    app_rate: 0.29,
    rank: 57,
    avg_round: 189,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.29,
    rank: 57,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.29,
    rank: 57,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.27,
    rank: 60,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.25,
    rank: 61,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 62,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 63,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 63,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.21,
    rank: 63,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 63,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 63,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 68,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 68,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.18,
    rank: 68,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.18,
    rank: 68,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 68,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.18,
    rank: 68,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 68,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.16,
    rank: 75,
    avg_round: 198,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 75,
    avg_round: 202,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.16,
    rank: 75,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 75,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 79,
    avg_round: 157,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 79,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 81,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 81,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.12,
    rank: 81,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 81,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 81,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 81,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 81,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 88,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'pulchra',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 88,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 88,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 88,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.1,
    rank: 88,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 88,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 88,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 88,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 88,
    avg_round: 212,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.08,
    rank: 97,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 97,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 97,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 97,
    avg_round: 179,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 97,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 97,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 97,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 97,
    avg_round: 156,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.08,
    rank: 97,
    avg_round: 186,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 97,
    avg_round: 196,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 107,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 107,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 107,
    avg_round: 243,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'astra-yao',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 107,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 107,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 107,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 107,
    avg_round: 187,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'pulchra',
    app_rate: 0.06,
    rank: 107,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 107,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 107,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.06,
    rank: 107,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 107,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.06,
    rank: 107,
    avg_round: 199,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 107,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 107,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 107,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 107,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.06,
    rank: 107,
    avg_round: 169,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 107,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 107,
    avg_round: 248,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'evelyn',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 107,
    avg_round: 142,
    star_num: '4'
  }
];
