export const Shiyu6_2 = [
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 8.45,
    rank: 1,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 7.31,
    rank: 2,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 5.17,
    rank: 3,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 5.11,
    rank: 4,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 3.46,
    rank: 5,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 3.4,
    rank: 6,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 3.32,
    rank: 7,
    avg_round: 122,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 3.28,
    rank: 8,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 2.99,
    rank: 9,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 2.91,
    rank: 10,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 2.49,
    rank: 11,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 2.23,
    rank: 12,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 2.23,
    rank: 12,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 2.08,
    rank: 14,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.87,
    rank: 15,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 1.42,
    rank: 16,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'caesar',
    app_rate: 1.38,
    rank: 17,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 1.13,
    rank: 18,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 1.05,
    rank: 19,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.95,
    rank: 20,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.89,
    rank: 21,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.89,
    rank: 21,
    avg_round: 137,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.87,
    rank: 23,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.86,
    rank: 24,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'rina',
    app_rate: 0.82,
    rank: 25,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.8,
    rank: 26,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.76,
    rank: 27,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.72,
    rank: 28,
    avg_round: 211,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.7,
    rank: 29,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.68,
    rank: 30,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'nicole-demara',
    app_rate: 0.66,
    rank: 31,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.64,
    rank: 32,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.62,
    rank: 33,
    avg_round: 175,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.6,
    rank: 34,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.6,
    rank: 34,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.52,
    rank: 36,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.51,
    rank: 37,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.49,
    rank: 38,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.49,
    rank: 38,
    avg_round: 182,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.49,
    rank: 38,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.49,
    rank: 38,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.47,
    rank: 42,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 43,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.43,
    rank: 43,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 43,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.41,
    rank: 46,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.41,
    rank: 46,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.41,
    rank: 46,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.41,
    rank: 46,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.37,
    rank: 50,
    avg_round: 164,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.35,
    rank: 51,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.35,
    rank: 51,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.33,
    rank: 53,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.33,
    rank: 53,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.33,
    rank: 53,
    avg_round: 119,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.33,
    rank: 53,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.29,
    rank: 57,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.29,
    rank: 57,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.29,
    rank: 57,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.29,
    rank: 57,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.27,
    rank: 61,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.25,
    rank: 62,
    avg_round: 163,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 63,
    avg_round: 167,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.23,
    rank: 63,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'miyabi',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 63,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 63,
    avg_round: 175,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.21,
    rank: 67,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.21,
    rank: 67,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.21,
    rank: 67,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 70,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.19,
    rank: 70,
    avg_round: 174,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 70,
    avg_round: 92,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.19,
    rank: 70,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 74,
    avg_round: 162,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 74,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 74,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 74,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 74,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.17,
    rank: 74,
    avg_round: 188,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 74,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 74,
    avg_round: 121,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 82,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 82,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.16,
    rank: 82,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.14,
    rank: 85,
    avg_round: 165,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 85,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 85,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.14,
    rank: 85,
    avg_round: 188,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 85,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 85,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 85,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.14,
    rank: 85,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 85,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 85,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 95,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 95,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 95,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.12,
    rank: 95,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 95,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 95,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.12,
    rank: 95,
    avg_round: 151,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 95,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 103,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 103,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 103,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 103,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 103,
    avg_round: 83,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 103,
    avg_round: 181,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.1,
    rank: 103,
    avg_round: 208,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 103,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 103,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 103,
    avg_round: 163,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 103,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 103,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'miyabi',
    char_three: 'yanagi',
    app_rate: 0.1,
    rank: 103,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 103,
    avg_round: 187,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 103,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 118,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 118,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 118,
    avg_round: 172,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 118,
    avg_round: 174,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.08,
    rank: 118,
    avg_round: 172,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 118,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 118,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 118,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 118,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 118,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'qingyi',
    app_rate: 0.08,
    rank: 118,
    avg_round: 176,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 129,
    avg_round: 152,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 129,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 129,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 129,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'pulchra',
    app_rate: 0.06,
    rank: 129,
    avg_round: 155,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 129,
    avg_round: 169,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 129,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 129,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 129,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 129,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 129,
    avg_round: 142,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'miyabi',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 129,
    avg_round: 173,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 129,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 129,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'grace-howard',
    app_rate: 0.06,
    rank: 129,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 129,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 129,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 129,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 129,
    avg_round: 192,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 129,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 129,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 129,
    avg_round: 202,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lycaon',
    app_rate: 0.06,
    rank: 129,
    avg_round: 277,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 129,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.06,
    rank: 129,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 129,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 129,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 129,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'grace-howard',
    app_rate: 0.06,
    rank: 129,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 129,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 129,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 129,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 129,
    avg_round: 181,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 129,
    avg_round: 343,
    star_num: '4'
  }
];
