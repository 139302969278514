export const Shiyu6_1 = [
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 8.08,
    rank: 1,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 7.68,
    rank: 2,
    avg_round: 73,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 7.05,
    rank: 3,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 6.45,
    rank: 4,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 4.88,
    rank: 5,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 4.1,
    rank: 6,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 3.28,
    rank: 7,
    avg_round: 84,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 2.31,
    rank: 8,
    avg_round: 88,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 2.12,
    rank: 9,
    avg_round: 80,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 2.08,
    rank: 10,
    avg_round: 96,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 2.0,
    rank: 11,
    avg_round: 126,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.98,
    rank: 12,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 1.83,
    rank: 13,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 1.67,
    rank: 14,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 1.59,
    rank: 15,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.5,
    rank: 16,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 1.44,
    rank: 17,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 1.4,
    rank: 18,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 1.15,
    rank: 19,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.15,
    rank: 19,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.11,
    rank: 21,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 1.01,
    rank: 22,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.95,
    rank: 23,
    avg_round: 98,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.93,
    rank: 24,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.89,
    rank: 25,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.8,
    rank: 26,
    avg_round: 86,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.72,
    rank: 27,
    avg_round: 120,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.7,
    rank: 28,
    avg_round: 73,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.68,
    rank: 29,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.66,
    rank: 30,
    avg_round: 90,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.64,
    rank: 31,
    avg_round: 124,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.62,
    rank: 32,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.62,
    rank: 32,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.56,
    rank: 34,
    avg_round: 90,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.56,
    rank: 34,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.56,
    rank: 34,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.54,
    rank: 37,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.47,
    rank: 38,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.45,
    rank: 39,
    avg_round: 150,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.43,
    rank: 40,
    avg_round: 85,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.43,
    rank: 40,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 40,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.41,
    rank: 43,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.39,
    rank: 44,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.39,
    rank: 44,
    avg_round: 110,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.37,
    rank: 46,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'pulchra',
    app_rate: 0.37,
    rank: 46,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.37,
    rank: 46,
    avg_round: 76,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.37,
    rank: 46,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.35,
    rank: 50,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.29,
    rank: 51,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.29,
    rank: 51,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.25,
    rank: 53,
    avg_round: 99,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 54,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.23,
    rank: 54,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 54,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 54,
    avg_round: 111,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.21,
    rank: 58,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 58,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 60,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 60,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.19,
    rank: 60,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 60,
    avg_round: 101,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 64,
    avg_round: 103,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 64,
    avg_round: 131,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.17,
    rank: 64,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.17,
    rank: 64,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.17,
    rank: 64,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 69,
    avg_round: 127,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 69,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 69,
    avg_round: 129,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.16,
    rank: 69,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 73,
    avg_round: 89,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 73,
    avg_round: 138,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 73,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.14,
    rank: 73,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 73,
    avg_round: 77,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 73,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.14,
    rank: 73,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 73,
    avg_round: 160,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 73,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'evelyn',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 73,
    avg_round: 80,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'pulchra',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 83,
    avg_round: 134,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 83,
    avg_round: 114,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.12,
    rank: 83,
    avg_round: 175,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.12,
    rank: 83,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.12,
    rank: 83,
    avg_round: 97,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 83,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 83,
    avg_round: 170,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 83,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 83,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.12,
    rank: 83,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.12,
    rank: 83,
    avg_round: 141,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 83,
    avg_round: 153,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 95,
    avg_round: 149,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 95,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 95,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 95,
    avg_round: 144,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.1,
    rank: 95,
    avg_round: 180,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 95,
    avg_round: 108,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 95,
    avg_round: 104,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 95,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 95,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 95,
    avg_round: 146,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 95,
    avg_round: 117,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 95,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.1,
    rank: 95,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 95,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 95,
    avg_round: 113,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 95,
    avg_round: 125,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 95,
    avg_round: 105,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.1,
    rank: 95,
    avg_round: 167,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 95,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 95,
    avg_round: 130,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.08,
    rank: 115,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 93,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 115,
    avg_round: 107,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 106,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 115,
    avg_round: 123,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 115,
    avg_round: 135,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 115,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 115,
    avg_round: 162,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'anby-demara-soldier-0',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 115,
    avg_round: 136,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 112,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 140,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 115,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.08,
    rank: 115,
    avg_round: 200,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 115,
    avg_round: 168,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.08,
    rank: 115,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 135,
    avg_round: 204,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 135,
    avg_round: 139,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 135,
    avg_round: 158,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 135,
    avg_round: 118,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 135,
    avg_round: 171,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 135,
    avg_round: 167,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 135,
    avg_round: 147,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'pulchra',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 135,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 135,
    avg_round: 148,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 135,
    avg_round: 115,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'pulchra',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 135,
    avg_round: 132,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'astra-yao',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 135,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 135,
    avg_round: 82,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 135,
    avg_round: 102,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 135,
    avg_round: 94,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 135,
    avg_round: 116,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 135,
    avg_round: 100,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 135,
    avg_round: 78,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 135,
    avg_round: 145,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 135,
    avg_round: 133,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 135,
    avg_round: 154,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 135,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'pulchra',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 135,
    avg_round: 169,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 135,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 135,
    avg_round: 128,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 135,
    avg_round: 109,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 135,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 135,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 135,
    avg_round: 206,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 135,
    avg_round: 600,
    star_num: '4'
  },
  {
    char_one: 'evelyn',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 135,
    avg_round: 128,
    star_num: '4'
  }
];
